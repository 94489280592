// body
& {
	letter-spacing: 0.9px;

	@include media-breakpoint-up(xl) {
		font-size: 18px;
		line-height: 1.75;
	}
}

#jump-to-overview {
	position: relative;
	top: -120px;
}

// headers
h1,
h2,
h3,
h4,
h5,
h6 {
	letter-spacing: 0;
}

h1,
h2 {
	text-transform: uppercase;
	font-family: $font-family-secondary;

	span {
		color: $yellow;
		font-style: normal !important;
		font-weight: 400 !important;
		text-decoration: none !important;
		text-shadow: 3px 3px 0 $blue-dark;
	}
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border: none;
	border-radius: 10px;
	background-color: $blue;

	.card-body {
		@include media-breakpoint-up(xl) {
			padding: 25px;
		}

		.card-caption {
			color: $white;

			.card-title {
				text-transform: uppercase;
			}
			.card-subtitle {
				font-size: 18px;
				font-style: italic;
			}
		}

		.card-tags {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 5px;

			.card-tags-item {
				margin: 0 5px 5px 0;
				padding: 5px;
				background: rgba($white, 0.5);
				border-radius: 5px;
				color: $white;
				font-size: 12px;
				font-weight: 700;
				line-height: 1;
				text-transform: uppercase;
			}
		}
	}
}

// gallery
.gallery {
	.gallery-link {
		border-radius: 10px;
	}
}

// wysiwyg
.wysiwyg {
	img {
		border-radius: 10px;
	}
}

// filter-tags
.filter-tags {
	flex-wrap: wrap;
	margin-bottom: 15px;
	gap: 0;

	.filter-tag {
		gap: 0;
		flex-direction: row-reverse;
		margin: 0 15px 15px 0;
		padding: 10px 15px;
		border-radius: 5px;
		color: $white;
		font-weight: 700;
		line-height: 1;
		letter-spacing: 0.9px;
		text-transform: uppercase;
		background-color: $blue;

		.filter-tag-icon {
			margin-left: 5px;

			&::before {
				content: "\f111";
				font-family: $font-awesome;
				font-size: 16px;
				;
				font-weight: 400;
			}

			i {
				display: none;
			}
		}

		&.active,
		&:hover {
			background-color: $green;
		}

		&.active {
			.filter-tag-icon {
				&::before {
					content: "\f058";
					font-weight: 700;
				}
			}
		}

	}
}
