// btn
.btn {
	border-radius: 5px;
	padding: 10px 15px;

	@include media-breakpoint-up(xl) {
		font-size: 18px;
		padding: 20px 30px;
		border-radius: 10px;
	}

	color: $white;
	font-weight: 700;
	line-height: 1;
	letter-spacing: 0.9px;
	text-transform: uppercase;

	// btn-primary
	&.btn-primary {
		&:hover {
			background-color: $green;
			border-color: $green;
			color: $white;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		color: $black;

		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		color: $black;

		&:hover {}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
