&.bundle-category,
&.bundle-detail {
	.service_category_detail,
	.service_detail {
		width: 100%;

		> .container:first-of-type {
			.container-holder {
				text-align: center;
				justify-content: center;
				padding: 55px;
			}
		}

		> .container-fluid {
			.container-holder {
				margin: 0;
			}
		}

		> [class*='container'] {
			margin-bottom: 15px;
		}
	}

	.descriptions {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		margin-bottom: 50px;

		.description-column {
			flex: 0 0 calc(50% - 30px);
			max-width: calc(50% - 30px);
			@include media-breakpoint-down(md) {
				flex: 0 0 100% !important;
				max-width: 100% !important;
				padding: 0 15px;
			}
		}
	}
}

.gallery {
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	justify-content: space-between;
	margin: 0;

	.large-item {
		flex: 0 0 calc(50% - 10px) !important;
		max-width: calc(50% - 10px) !important;
		padding: 0;
		margin-bottom: 14px;
		@include media-breakpoint-down(md) {
			flex: 0 0 100% !important;
			max-width: 100% !important;
		}
	}

	.gallery-thumbs {
		flex: 0 0 calc(50% - 10px);
		max-width: calc(50% - 10px);
		display: flex;
		flex-flow: row wrap;
		@include media-breakpoint-down(md) {
			flex: 0 0 100% !important;
			max-width: 100% !important;
		}

		> div {
			flex: 0 0 50%;
			max-width: 50%;
			margin-bottom: 0;
			@include media-breakpoint-down(md) {
				margin-bottom: 15px;
			}

			&.last-item {
				display: block !important;
			}

			&:nth-of-type(2n + 1) {
				padding-left: 0;
			}

			&:nth-of-type(2n + 2) {
				padding-right: 0;
			}

			.gallery-link {
				border-radius: 10px !important;
			}
		}
	}
}


.service_detail,
.service_category_detail {
	.back-to-overview-link {
		flex: 0 0 100%;
		@include media-breakpoint-up(sm) {
			flex: 0 0 auto;
			position: absolute;
			left: 35px;
		}
	}
}

.bundle-overview-section {
	.other-service-item-wrapper.item {
		.other-service-item {
			background: $tertiary;
			border-radius: 10px;

			.card-body,
			h3.card-title,
			h4.card-subtitle {
				color: #fff;
			}

			h4.card-subtitle {
				margin-bottom: 0.75rem;
			}

			.card-caption {
				min-height: 190px;
			}

			.card-title-link {
				text-decoration: none;
			}

			.card-buttons {
				margin-top: 15px;
			}
		}
	}
}
