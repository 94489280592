// footer
.footer {
	padding: 4.5vh 0;
	background-color: $white;
	font-size: 16px;
	line-height: 1.6;
	letter-spacing: 0;

	a {
		color: $blue-dark;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	// footer-text
	.footer-text {
		h6 {
			color: $black;
			letter-spacing: 0.85px;
			text-transform: uppercase;
		}

		ul {
			padding-left: 0;
			list-style: none;
		}
	}

	// footer-logolink
	.footer-logolink {

		.list {

			.list-item {
				margin-right: 20px;

				.link {
					display: block;
					font-size: 24px;

					&:hover {
						color: $yellow;
					}
				}
			}
		}
	}

	// footer-bottom
	.footer-bottom {
		margin-top: 30px;

		.logo {
			max-width: 75%;
		}
	}

}
