// eyecatcher
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large .owl-carousel .item {
	height: 85vh;
	min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: calc(100vh - 50px);
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	max-height: 1080px;
	overflow: hidden;

	&:not(.large) {
		&::after {
			content: "";
			z-index: 100;
			position: absolute;
			top: 40px;
			right: 25%;
			width: 177px;
			height: 173px;
			background: url('/images/compas-white.svg');
			background-size: 100%;

			@include media-breakpoint-down(lg) {
				top: 30px;
				right: 30px;
				width: 102px;
				height: 100px;
			}

		}
	}

	// vimeo-video
	.vimeo-video {
		position: relative;
		padding-top: 56.25%;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.owl-carousel {
		.item {

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba($black, 0.2);
			}

			.owl-container {
				z-index: 100;
				max-width: 720px;

				.owl-caption {
					position: relative;
					text-shadow: none;

					&::before {
						content: "";
						position: absolute;
						left: -25px;
						bottom: -25px;
						width: 91px;
						height: 91px;
						background: url('/images/wing-bottom-left.svg');
						background-size: 100%;

						@include media-breakpoint-down(lg) {
							left: -15px;
							bottom: -15px;
							width: 45px;
							height: 45px;
						}
					}

					&::after {
						content: "";
						z-index: -1;
						position: absolute;
						top: -110px;
						right: -100px;
						width: 177px;
						height: 173px;
						background: url('/images/compas-white.svg');
						background-size: 100%;

						@include media-breakpoint-down(lg) {
							top: -50px;
							right: -50px;
							width: 102px;
							height: 100px;
						}
					}

					@include media-breakpoint-down(md) {
						//margin-bottom: 115px;
					}

					@include media-breakpoint-up(lg) {
						//margin-bottom: 230px;
					}

					.owl-description {
						line-height: 1;
						font-weight: 700;
						text-transform: uppercase;
						font-family: $font-family-secondary;
						@include font-size($h1-font-size);

						@include media-breakpoint-up(xl) {
							font-size: 80px;
						}

						span {
							color: $yellow;
							font-style: normal !important;
							font-weight: 400 !important;
							text-decoration: none !important;
							text-shadow: 2px 2px 0 $white;

							@include media-breakpoint-up(xl) {
								text-shadow: 3px 3px 0 $white;
							}
						}
					}

					.owl-btn {
						background-color: transparent;
						border-color: $white;

						&:hover {
							background-color: $green;
							border-color: $green;
							color: $white;
						}
					}
				}
			}
		}
	}

	// owl
	.owl-nav,
	.owl-dots {
		display: none;
	}
}

&.home {
	.eyecatcher {
		max-height: 70vh;
	}
}
