// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: '\f00c';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 900;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		min-height: 39px;
		margin-bottom: 15px;
		padding: 0 0 0 55px;
		font-size: 14px;
		line-height: 1;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 41px;
			height: 39px;
			background-image: url('/images/custom-list-icon.svg');
		}

		strong {
			display: block;
			margin-bottom: 5px;
			font-size: 18px;
			font-weight: 500;
		}

		br {
			display: none;
		}
	}
}
