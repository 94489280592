// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// lead-section
// =========================================================================
.lead-section {
	position: relative;
	margin: 6vh 0;
	letter-spacing: 0.9px;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

&.home {
	.lead-section {
		position: relative;
		margin: 0;
		padding-top: calc(6vh + 40px);
		padding-bottom: 6vh;
		overflow: hidden;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 50vw;
			height: 100vh;
			max-width: 800px;
			max-height: 640px;
			background: url('/images/kaart-bg.jpg') no-repeat;
			background-size: 100%;

			@include media-breakpoint-down(sm) {
				width: 100vw;
			}
		}

		&::after {
			content: "";
			position: absolute;
			right: -150px;
			bottom: -150px;
			width: 477px;
			height: 485px;
			background: url('/images/compas-large-transparent.svg') no-repeat;
			background-size: 100%;
			opacity: 0.5;

			@include media-breakpoint-down(lg) {
				width: 239px;
				height: 243px;
				right: -100px;
				bottom: -100px;
			}
		}

		.container-two-columns {
			max-width: 1280px;

			.column.one {
				img {
					max-width: 50%;
				}
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// tickets-collection-section
// =========================================================================
.tickets-collection-section {
	position: relative;
	padding-top: 85px;
	padding-bottom: 6vh;
	background: $purple-light;

	&::before {
		content: "";
		position: absolute;
		top: -55px;
		left: 0;
		right: 0;
		width: 110px;
		height: 110px;
		margin: 0 auto;
		padding: 10px;
		border-radius: 100%;
		background: url('/images/compas-blue.svg') $purple-light center center no-repeat;
		background-size: 102px 100px;
	}

	.container {
		max-width: 930px;
	}

	/* wysiwyg */
	.wysiwyg {
		margin-bottom: 30px;
		text-align: center;
	}

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}
				}
			}
		}

		.card {
			border: none;
			border-radius: 10px;
			background-color: $blue;

			.card-image {
				display: none;
			}

			.card-body {
				padding: 25px;

				@include media-breakpoint-up(xl) {
					padding: 50px;
				}

				.card-caption {
					color: $white;

					.card-title {
						text-align: center;
						text-transform: uppercase;
					}

					.card-description {
						margin: 20px 0 30px 0;

						ul {
							@extend .custom-list-extra;
						}
					}

				}

				.card-price {
					position: relative;
					padding: 10px;
					border-radius: 5px;
					background-color: $white;
					box-shadow: 10px 10px 10px rgba($black, 0.15);
					line-height: 1.2;
					text-align: center;

					&::after {
						content: "";
						position: absolute;
						top: 100%;
						left: 0;
						right: 0;
						width: 0;
						height: 0;
						margin: 0 auto;
						border-top: 18px solid $white;
						border-left: 20px solid transparent;
						border-right: 20px solid transparent;
						transition: 0.5s;
					}

					.card-price-value {
						font-size: 24px;
						font-weight: 700;
					}

					.card-price-label {
						font-size: 14px;
					}
				}

				.card-buttons {
					margin-top: 40px;
					text-align: center;

					a {
						@include media-breakpoint-up(xl) {
							padding-left: 45px;
							padding-right: 45px;
						}
					}
				}
			}
		}
	}

}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	position: relative;
	padding-top: 85px;
	padding-bottom: 6vh;
	background: $purple-light;

	&::before {
		content: "";
		position: absolute;
		top: -55px;
		left: 0;
		right: 0;
		width: 110px;
		height: 110px;
		margin: 0 auto;
		padding: 10px;
		border-radius: 100%;
		background: url('/images/compas-blue.svg') $purple-light center center no-repeat;
		background-size: 102px 100px;
	}

	/* wysiwyg */
	.wysiwyg {
		margin-bottom: 30px;
		text-align: center;
	}

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	position: relative;
	margin-top: calc(6vh + 55px);
	padding-top: 85px;
	padding-bottom: 6vh;
	background: $green;

	&::before {
		content: "";
		position: absolute;
		top: -55px;
		left: 0;
		right: 0;
		width: 110px;
		height: 110px;
		margin: 0 auto;
		padding: 10px;
		border-radius: 100%;
		background: url('/images/compas-white.svg') $green center center no-repeat;
		background-size: 102px 100px;
	}

	/* wysiwyg */
	.wysiwyg {
		margin-bottom: 30px;
		text-align: center;
	}

	// collection
	.collection {
		.card {
			border: none;
			border-radius: 10px;
			background-color: $white;

			.card-body {
				padding: 30px 15px;

				.card-caption {
					color: $black;

					.card-title {
						text-transform: uppercase;
					}
				}
			}
		}
	}
}

// photos-section
// =========================================================================
.photos-section {
	margin: 6vh 0;

	// image-collection
	.image-collection {
		.gallery-grid {
			justify-content: center;
			margin: 0 -5px -10px -5px;

			.gallery-item {
				margin-bottom: 10px;
				padding-left: 5px;
				padding-right: 5px;
				@include make-col(4);

				@include media-breakpoint-up(sm) {
					@include make-col(3);
				}

				@include media-breakpoint-up(md) {
					@include make-col(3);
				}

				@include media-breakpoint-up(lg) {
					@include make-col(2);
				}

				@include media-breakpoint-up(xl) {
					@include make-col(2);
				}

				.gallery-link {
					.gallery-caption {
						display: none;
					}
				}
			}
		}
	}

}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
}
