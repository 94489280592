&.timeslot-overview {
    .timeslot-overview {
        color: $body-color;
        .timeslot-datepicker {
            padding: 0;
            .datepicker-switch {
                color: $tertiary;
                font-weight: 700;
                text-transform: uppercase;
            }
            .prev,
            .next {
                background-color: $primary;
                color: $white;

                &:hover {
                    background-color: $secondary;
                }
            }
            .dow {
                color: $primary;
                font-size: 20px;
                font-weight: 400; 
            }
        }
        .timeslot-item {
            // background-color: transparent;
            background-color: $tertiary;
            border: none;
            &-navigation {
                &-btn {
                    min-width: auto;
                    padding: 0.475rem 1rem;
                    border-radius: 4px;
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-family: "DM Sans", sans-serif;
                    line-height: 1.5;
                }
                &-duration {
                    padding: 0;
                    color: $primary;
                    font-size: 13px;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }

            &-title {
                color: $white;
            }

            .timeslot-item-days {
                .timeslot-item-day {
                    &-title,
                    &-times {
                        color: $white;
                        &-holder {
                            @include make-col(6);
                        }
                    }
                    &-title {
                        padding: 2px 0;
                        background: transparent;
                        font-weight: 400;
                    }

                    &-times {
                        padding: 2px 0;
                        background: transparent;
                        font-weight: 700;
                    }
                }
            }
        }
    }
    
}