// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	transition: 0.5s;
	background-color: $white;
	padding: 10px 0;

	@include media-breakpoint-up(xl) {
		padding: 20px 0;
	}

	&.sticky {
		padding: 10px 0;
		background: $white;
		box-shadow: $shadow;
	}

	.container-fluid {
		.container-holder {
			> .column {
				@extend .align-items-center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;

				@media only screen and (min-width: 1320px) {
					padding: 0 45px;
				}

			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			width: 40px;
			height: 40px;
			margin: 0 10px 0 -10px;
			padding: 0;
			border: none;
			color: $black;
			font-size: 24px;
			font-weight: 700;
			transition: color 0.5s;

			i {
				line-height: 40px;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 110px;
		margin-right: auto;
		transition: width 0.5s;

		@include media-breakpoint-only(lg) {
			width: 150px;
		}

		@include media-breakpoint-up(xl) {
			width: 195px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	&.sticky {
		.logo {
			width: 110px;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				margin-top: 15px;

				> .nav-item {
					> .nav-link {
						display: flex;
						align-items: center;
						color: $black;
						font-size: 17px;
						font-weight: 700;
						text-transform: uppercase;

						&:hover {
							color: $green;
						}

						&.dropdown-toggle {
							&::after {
								content: "\f107";
								font-size: 20px;
								font-family: $font-awesome;
								border: none;
							}
						}
					}

					&.active {
						> .nav-link {
							color: $green;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0;
						padding: 0 0 0 20px;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 17px;
							font-weight: 400;

							.nav-link {
								color: $black;

								&:hover {
									color: $green;
								}

								&:first-of-type {
									.nav-link {
										padding-top: 0;
									}
								}
							}

							&.active {
								.nav-link {
									color: $green;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin-left: auto;

				> .nav-item {
					margin-right: 20px;

					> .nav-link {
						padding-left: 0 !important;
						padding-right: 0 !important;
						color: $black;
						font-size: 17px;
						font-weight: 700;
						text-transform: uppercase;

						&:hover {
							color: $green;
						}
					}

					&.active {
						> .nav-link {
							color: $green;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						min-width: 300px;
						margin-top: 10px;
						padding: 1rem;
						background-color: $green;
						border-radius: 5px;
						border: none;
						box-shadow: 10px 10px 10px rgba($black, 0.15);

						&::before {
							border-left: 20px solid transparent;
							border-right: 20px solid transparent;
							border-bottom: 18px solid $green;
						}

						&::after {
							display: none;
						}

						.nav-item {
							font-size: 18px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $white;

								&:hover {
									color: $yellow;
								}
							}

							&.active {
								.nav-link {
									color: $yellow;
								}
							}
						}
					}
				}
			}
		}
	}

	// tickets-button
	div.tickets-button {
		margin-left: 15px;

		ul {
			li {
				a {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 114px;
					height: 47px;
					background: url('/images/tickets-button.svg');
					background-size: 100%;
					color: $white;
					font-size: 17px;
					font-weight: 700;
					text-transform: uppercase;
					text-decoration: none;
					overflow: hidden;

					@include media-breakpoint-down(lg) {
						width: 80px;
						height: 33px;
						font-size: 11px;
					}

					&:hover {
						transform: rotate(3deg);
						text-shadow: 2px 2px 0 $green;
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 15px;

		.language {
			flex-wrap: nowrap !important;

			.nav-item {
				+ li {
					@include media-breakpoint-up(xl) {
						margin-left: 10px;
					}
				}

				.nav-link {
					border: none;
					color: $black;

					&:hover {
						color: $yellow;

						&:not(.active) {
							box-shadow: none;
						}
					}
				}
			}
		}
	}
}

// home
&.home {
	.header {
		@include media-breakpoint-up(xl) {
			position: fixed;
		}
	}
}

.header .menu .navbar-nav > .nav-item > .nav-link {
	@media(max-width: 1399px) {
		font-size: 15px !important;
	}
	@media(max-width: 1299px) {
		font-size: 14px !important;
	}
}
