&.faq-overview {
	.faq-overview {
		.faq-icon {
			color: $blue-dark;
		}

		.faq-category {
			.faq-title {
				padding-left: 0;
				pointer-events: none;

				.faq-icon {
					display: none !important;
				}
			}

			.faq-items {
				display: block !important;

				.faq-item {
					.faq-question {
						border: 1px solid rgba($white, 0.5);
					}

					.faq-answer {
						.wysiwyg {
							margin-bottom: 0;
							text-align: left;
						}

						p {
							&:last-of-type {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}
