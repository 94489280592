// plan-your-visit
.plan-your-visit-section {
	min-height: 40px;

	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;
		max-width: 840px;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 0;
			width: 100%;

			@include media-breakpoint-down(md) {
				margin-top: -75px;
			}

			@include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: -40px;
			}

			>.column {
				padding: 0;
			}
		}
	}

	// plan-your-visit
	.plan-your-visit {

		.nav-tabs {
			margin: 0 -5px;
			border-bottom: none;
			justify-content: center;

			.nav-item {
				margin: 0 5px;
				font-size: 12px;

				@include media-breakpoint-up(sm) {
					font-size: 20px;
				}

				font-weight: 700;
				text-align: center;
				text-transform: uppercase;

				.nav-link {
					position: relative;
					margin-bottom: 0;
					padding: 5px 10px;

					@include media-breakpoint-up(sm) {
						padding: 10px 25px;
					}

					color: $white;
					border: 0;
					border-radius: 10px 10px 0 0;
					line-height: 30px;

					&::after {
						content: "";
						position: absolute;
						top: 100%;
						left: 0;
						right: 0;
						width: 0;
						height: 0;
						margin: 0 auto;
						border-top: 20px solid transparent;
						border-left: 20px solid transparent;
						border-right: 18px solid transparent;
						transition: 0.5s;
					}

					&[id^="visit-tab"] {
						background-color: $blue;
					}

					&[id^="stay-tab"] {
						background-color: $green;
					}

					&:hover,
					&.active {
						color: $white;
						border-radius: 5px;
						box-shadow: 10px 10px 10px rgba($black, 0.15);

						&[id^="visit-tab"] {

							&::after {
								border-top-color: $blue;
							}
						}

						&[id^="stay-tab"] {

							&::after {
								border-top-color: $green;
							}
						}
					}
				}
			}
		}

		.tab-content {
			.tab-pane {
				min-height: 80px;
				padding: 20px 15px;

				@include media-breakpoint-up(md) {
					padding: 20px 40px;
				}

				background: $blue;
				border-radius: 10px;
				color: $white;

				&-stay {
					&.active {
						background: $green;
					}
				}

				.tab-pane-holder {
					display: flex;
					align-items: center;
					flex-direction: column;

					@include media-breakpoint-up(sm) {
						flex-direction: row;
					}

					.text {
						flex: 1;
						font-size: 14px;
						line-height: 1.5;

						strong {
							font-size: 18px;
						}

					}

					.buttons {
						display: flex;
						justify-content: flex-end;
						flex: 1;

						@include media-breakpoint-down(sm) {
							margin-top: 10px;
						}

						a {
							@include media-breakpoint-up(xl) {
								padding: 10px 30px;
								border-radius: 5px;
							}
						}
					}
				}
			}
		}
	}
}

&.home {
	.plan-your-visit-section {
		min-height: auto;
	}
}
